.filters__header{
  height: 44px;
}
.filters__header_back{
  display: flex;
  height: 44px;
  padding: 11px 6px;
  color: #007AFF;
}
.filters__title{
  color: #000;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 590;
  line-height: 22px;
  letter-spacing: -0.43px;
}
.filters__container{
  padding: 31px 19px;
  height: calc(100vh - 65px);
}
.filters__input{
  display: flex;
  height: 48px;
  padding: 0px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #C4C2C8;
  background: #FFF;
  width: 100%;
}
.filters__input_label{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}