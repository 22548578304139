.card__mobile{
  border-radius: 12px;
  background: #FFF;
  padding: 6px 8px 12px 13px;
}
.card__mobile__price{
  font-size: 6vw;
  font-style: normal;
  font-weight: 700;
  line-height: 40.417px;
}
.card__mobile__date{
  display: inline-flex;
  padding: 2.4688vw 3.7813vw;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1vw;
  background-color: #F4F4F4;
  font-size: 3.5vw;
  font-weight: 600;
  line-height: 16px;
}
.card__mobile__department{
  display: inline-flex;
  padding: 2.4688vw 3.7813vw;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1vw;
  background-color: #F4F4F4;
  font-size: 3.5vw;
  color: #0055FF;
  font-weight: 600;
  line-height: 16px;
}
.card__mobile_box{
  padding: 3px 12px;
  width: 92vw;
}
.card__text_16{
  color: #0E1117;
  font-size: 3.5vw;
  font-style: normal;
  font-weight: 400;
}
.card__text_14_bold{
  font-size: 3.2vw;
  font-style: normal;
  font-weight: 600;
}
.card__text_14{
  font-size: 3.2vw;
  font-style: normal;
  font-weight: 400;
}
.card__box_terminal{
  display: flex;
  padding: 1.4688vw 2.7813vw;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #e9e8e8;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums stacked-fractions;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 3vw;
  font-style: normal;
  font-weight: 600;
  margin-right: 8px;
}