.header__container{
   border-bottom: 1px solid #D8D8D8;
   padding: 12px 22px 11px 16px;
   gap: 6px;
   display: flex;
   align-items: center;
   background-color: #FFF;
}
.header__title{
   color: #5A00CE;
   text-align: center;
   font-variant-numeric: lining-nums tabular-nums stacked-fractions;
   font-feature-settings: 'liga' off, 'clig' off;
   font-size: 20px;
   font-style: normal;
   font-weight: 600;
   line-height: 16px; /* 80% */
}
.header__wrap{
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   cursor: pointer;
}
.mobile_header__title{
   color: #000;
   text-align: center;
   font-size: 17px;
   font-style: normal;
   font-weight: 590;
   line-height: 22px;
   letter-spacing: -0.43px;
}
.header__dropdown{
   cursor: pointer;
   border-radius: 6px;
   padding: 8px 38px 8px 13px;
   &:hover{
      background: #ECE6FF;
   }
   &:active{
      background: #ECE6FF;
   }
}