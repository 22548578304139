.main {
  background-color: #ECE6FF;
}

.main__mobile {
  background-color: #f3dee6;
  height: calc(100vh - 0px);
}

.clear__filters {
  cursor: pointer;
  color: #05F;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
}

.containers {
  width: 100%;
  padding: 0 16px 13px 16px;
  flex-direction: column;
  top: 126px;
  position: relative;
  background-color: #f3dee6;
  height: calc(86vh - 0px);
}

.skeleton_box {
  border-radius: 12px;
  background: #FFF;
  padding: 6px 8px 12px 13px;
}